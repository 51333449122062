
import "./stylesheet.css"

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );
let render = () => {
  
  require('./AppRenderer');
  if(process.env.REACT_APP_ENV === "production"){
       console.log = function () {};
  }
};
render();