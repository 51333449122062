import { combineReducers } from 'redux';
import settings from './settings/reducer';



const reducers = combineReducers({

  settings,
  
});

export default reducers;